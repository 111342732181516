.profile {
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 0px;
	gap: 16px;
	height: 48px;
	justify-content: center;
	/* Inside auto layout */
	flex: none;
	order: 2;
	flex-grow: 0;
}

.dropIcon{
    
position: absolute;
left: 25%;
right: 25%;
top: 37.5%;
bottom: 37.5%;
}

.dropItems {
	line-height: 10px;
	margin-top: -7px;
	margin-bottom: -7px;
	width: 100%;
}

.navStyle{
    margin-bottom: auto;
    box-shadow: 0px 5px 10px rgba(16, 24, 40, 0.05);
    
}

.get-api-btn {
    background: rgb(32, 154, 233);
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* .limited-seats-api {
    position: absolute;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
} */

#limited-seats-text {
	position: absolute;
	color: red;
	font-size: 9px;
	bottom: -13px;
	left: 0;
	border-radius: 3px;
	padding: 0rem 0.3rem;
	font-weight: 500;
}

/* #triangle-down {
    width: 0;
    height: 0;
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    border-top: 11px solid red;
  } */

  .elementor-button {
    display: flex;
    line-height: 1;
    background-color: #818a91;
    font-size: 15px;
    padding: 12px 24px;
    font-weight: 500;
    text-decoration: none;
    border: none;
    background: transparent linear-gradient(290deg,#8bd2db 0%,#209ae9 38%,#615cd9 89%,#615cd9 100%) 0% 0% no-repeat padding-box;
    transition: .3s ease;
    box-shadow: 0px 10px 26px rgba(46,113,239,.24);
    color: #fff !important;
    fill: #fff;
    border-radius: 10px;
    transform: scale(1);
    position: relative;
    text-align: center;
    margin-right: 1rem;
}

.schedule-call-btn {
    background: white;
    text-transform: none;
    color: #209ae9 !important;
    /* font-weight: 500; */
    box-shadow: 0px 10px 26px rgb(122 128 132 / 24%) !important;
}

.dropdown-cont {
	min-width: 14rem;
	width: 100%;
	display: block;
	position: absolute;
	top: 0px;
	right: 0px;
}

.dropdown-cont-mobile {
	display: none;
}

@media (max-width: 991px) {
	.dropdown-cont-mobile {
		display: block;
		min-width: 14rem;
	}
	.dropdown-cont {
		display: none;
	}
}
