.modal-backdrop.show{
    opacity: 0.5;
}
.api_input {
    font-size: 13px;
    height: 2.25rem;
    color: #202123;
    border: 1px solid #cccccc;
    box-shadow: none;
    outline: none;
    background: transparent;
}
.api_input::placeholder{
    color:#78838f;
}

.success-message-cta {
    font-size: 13px;
    color: #2dce89;
    display: flex;
    /* font-weight: 500; */
    margin-top: 1rem;
    text-align: center;
}