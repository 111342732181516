.table-action-hover-tooltip {
	background: grey !important;
	color: white !important;
	padding: 7px !important;
	border-radius: 5px !important;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1) !important;
	font-size: 12px !important;
	z-index: 9999 !important;
	margin-bottom: 0rem !important;
	cursor: pointer !important;
}
