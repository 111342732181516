html {
  position: relative;
  margin: 0 auto;
  height: 100vh;
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: "Inter", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #f2f4f7 !important;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.fs-14 {
  font-size: 14px !important;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.fa-arrow-left:hover,
.cursor:hover {
  cursor: pointer;
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.margin {
  margin-top: 30px;
}
.height {
  height: 283.38px;
}
.center {
  text-align: center;
}
.border {
  display: inline-block;
  width: 350px;
  height: 300px;
  margin: 6px;
}
.image {
  display: flex;
}
.preview {
  margin-top: 75px;
}
.image > * {
  display: flex;
  height: 400px;
  width: 413px;
  justify-content: center;
  align-items: center;
  border: 1px solid black;
}
.smloader,
.smloader:after {
  border-radius: 50%;
  width: 10em;
  height: 10em;
}
.smloader {
  margin: 0 auto;
  top: 40%;
  left: 47%;
  font-size: 10px;
  position: fixed;
  text-indent: -9999em;
  border-top: 1.1em solid rgba(255, 255, 255, 0.2);
  border-right: 1.1em solid rgba(255, 255, 255, 0.2);
  border-bottom: 1.1em solid rgba(255, 255, 255, 0.2);
  border-left: 1.1em solid #ffffff;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}
@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
#smloadingDiv {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: #88888877;
  z-index: 5300;
}
.hero-section {
  padding-top: 30px;
  padding-bottom: 30px;
}
.font-size-14 {
  font-size: 14px;
}
.borders-col {
  border-right: 0.0625rem solid #8898aa !important;
}
.cp {
  cursor: pointer;
}

.w-90 {
  margin: auto;
  width: 90%;
}

.not-allowed {
  cursor: not-allowed;
  opacity: 0.5;
}

.mainLogo {
  position: absolute;
  width: 125px;
  background: #ffffff;
  margin: 10px;
  padding: 5px;
}
.navbar-toggler span {
  background-color: #000;
}
.navbar-phone {
  font-size: 46px;
  padding-top: 5px;
}
.navbar-phone-no {
  color: #333333;
  background-color: transparent;
  transition: 0.5s;
  box-shadow: none;
  text-decoration: none;
}
.navbar-phone-no:hover {
  color: #074ece;
}
.footer {
  background-color: #0d1d3b;
  color: #fff;
  padding: 0;
  padding-bottom: 25px;
}
.copyright a:first-child {
  margin-left: 15px;
}
.copyright a {
  color: #fff;
  text-decoration: underline;
}
.copyright a:hover {
  color: #4ac728;
}
.place {
  font-size: 20px;
}
.contact {
  font-size: 3.25rem !important;
}

/* term condition */
.heading-sc {
  font-size: 55px;
  color: #333;
  font-weight: 600;
  margin-bottom: 0px;
}
.accept-terms {
  font-size: 28px;
  font-weight: 600;
  text-shadow: 0px 0px 0px rgb(0 0 0 / 30%);
  color: #333;
}
.accept-terms-para {
  color: #333333;
  font-family: "Source Serif Pro", Sans-serif;
  font-size: 16px;
  line-height: 26px;
  font-weight: 400;
  letter-spacing: 0.4px;
  margin-bottom: 15px;
}

.terms-condition li::marker {
  color: #285488;
}
.contentsec {
  padding-left: 0px;
  padding-right: 0px;
}
@media print {
  .noprint {
    visibility: hidden;
  }
}
.error-red {
  color: rgb(221, 67, 67) !important;
}
/* CONFIRM BOX ON JDMAKER PAGE */
button[role="confirmable-button"] {
  color: white;
  border: none;
  background: #f36e6e;
  padding: 0.2rem 0.6rem;
  border-radius: 8px;
}
button[role="confirmable-button"]:hover {
  background: rgb(220, 66, 66);
}
button[role="cancellable-button"] {
  background: #209ae9;
  color: white;
  border: none;
  padding: 0.2rem 0.6rem;
  border-radius: 8px;
}

button[role="cancellable-button"]:hover {
  background: #0576c1;
}
.confirm-box {
  left: 50% !important;
  top: 50% !important;
  /* left: none; */
}
.confirm-box > .confirm-box__content {
  position: absolute;
  transform: translate(-50%, -50%);
  padding: 1em 2em;
  width: 400px;
}
.confirm-box > .confirm-box__overlay {
  background-color: rgb(18 15 15 / 38%);
}

.form-control:focus {
  color: #2f323a;
}
.picker-dialog {
  z-index: 2001 !important;
}
.picker-dialog-bg {
  z-index: 2000 !important;
}
.close {
  position: absolute;
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1;
  color: rgba(0, 0, 0, 0.6);
  text-shadow: none;
  right: 0.35rem;
  top: 0.15rem;
}
.close:hover {
  color: red;
  cursor: pointer;
}
.trashButton {
  display: flex;
  align-items: center;
}
.toastr {
  display: flex;
  align-items: center;
}
.redux-toastr .toastr .rrt-middle-container {
  width: 71%;
}
.hover-red:hover {
  color: red;
}

.vert-move {
  -webkit-animation: mover 0.5s infinite alternate;
  animation: mover 0.5s infinite alternate;
}
/* .vert-move {
  -webkit-animation: mover 1s infinite  alternate;
  animation: mover 1s infinite  alternate;
} */
@-webkit-keyframes mover {
  0% {
    transform: translateY(2px);
  }
  100% {
    transform: translateY(-5px);
  }
}
@keyframes mover {
  0% {
    transform: translateY(2px);
  }
  100% {
    transform: translateY(-5px);
  }
}

.btn-outline-primary {
  color: #209ae9;
  border-color: #209ae9;
}

.btn-outline-primary:hover {
  background-color: #209ae9;
  color: white;
}

/* common table style */
.tablepad {
  padding: 20px;
  background-color: white;
  border-radius: 12px;
  width: 90%;
}

header {
  padding-left: 0px !important;
}

/* custom checkbox */
/* Custom checkbox and radio button */
/* Hide the default checkbox and radio button */
input::placeholder {
  font-size: 97%;
}

input[type="radio"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 19px;
  height: 19px;
  border: 2px solid #c1c1c1;
  border-radius: 2.5px;
  outline: none;
  cursor: pointer;
  position: relative;
  background-color: #fff;
  transition: background-color 0.3s, border-color 0.3s, box-shadow 0.3s;
  /* box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24); */
}

/* Radio button specific styles */
input[type="radio"] {
  border-radius: 50%;
}

/* Checked state for radio button */
input[type="radio"]:checked {
  background-color: #209ae9;
  border-color: #209ae9;
  /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.16), 0 2px 3px rgba(0, 0, 0, 0.23); */
}

/* Add a checkmark using ::after for checkbox */

/* Add a dot using ::after for radio button */
input[type="radio"]:checked::after {
  content: "";
  position: absolute;
  top: 4px;
  left: 4px;
  width: 7.5px;
  height: 7.5px;
  background-color: white;
  border-radius: 50%;
}

/* Responsive styles */
@media (max-width: 768px) {
  input[type="radio"] {
    width: 20px;
    height: 20px;
  }

  input[type="radio"]:checked::after {
    top: 5px;
    left: 5px;
    width: 6px;
    height: 6px;
  }
}

.dropdown-menu {
  min-width: 0rem !important;
}

/* custom clipboard hover */
.clipboard:hover {
  background-color: aliceblue !important;
}

/* custom skills select */
.select__value-container {
  /* width: 28rem;
	max-width: 28rem; */
  height: 33px !important;
  overflow: auto !important;
  max-width: 100%;
  overflow-x: auto;
}
.basic-multi-select-type .select__value-container {
  width: 11rem !important;
  height: 100% !important;
  overflow: auto !important;
}
@media (max-width: 1000px) {
  .select__value-container {
    /* width: 12rem; */
    max-width: 100%;
  }
  .basic-multi-select-type .select__value-container {
    width: 11rem !important;
  }
}

/* custom search input on table */
.input-group {
  flex-wrap: nowrap !important;
}

/* user weight range slider */
.slider-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  grid-gap: 20px;
}
